import * as React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useState } from "react"

const ImageSlider = ({ images }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const [slider, setSlider] = useState({ nav1: null, nav2: null })
  const [sliderNotClicked, setSliderNotClicked] = useState(true)
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()

  const updateClick = e => {
    setSliderNotClicked(false);
  }
  return (
    <>
    <div style={{
        marginBottom: 50
    }}>
      <Slider
        asNavFor={nav2}
        ref={slider1 => setNav1( slider1 ) }
        autoplay={sliderNotClicked}
        autoplaySpeed={2000}
        >
        {images.map(
          (image, imageIdx) => (
            <div key={imageIdx} className={`h-100 d-flex align-items-center justify-content-center`}>
                <div>
                    <img
                    onClick={updateClick}
                    className="img-fluid"
                    style={{
                        maxHeight: 600,
                    }}
                    src={image.localFile.publicURL}
                    />
                </div>
            </div>
          )
        )}
      </Slider>
    </div>
      <Slider
        asNavFor={nav1}
        ref={slider2 => setNav2( slider2 ) }
        slidesToShow={ images.length >= 3 ? 3 : images.length }
        infinite={true}
        focusOnSelect={true}
        centerMode={true}
      >
        {images.map(
          (image, imageIdx) => (
            <div key={imageIdx} className={`h-100 d-flex align-items-center justify-content-center`}>
                <img
                onClick={updateClick}
                className="img-fluid"
                style={{
                    cursor: `pointer`
                }}
                src={image.localFile.publicURL}
                />
            </div>
          )
        )}
      </Slider>
    </>
  )
}

export default ImageSlider
