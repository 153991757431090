import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageSlider from "../components/image-slider";
import { FiletypeDoc, FiletypePdf } from 'react-bootstrap-icons';
import './product.css';

const Product = ({ pageContext: { slug, data }}) => {
    return (
      <Layout headerColorMode="light" pageTitle={data.title} enableBorderAnim>
        <section className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
                {
                  data.images?.length === 1 &&
                  <div className="d-flex align-items-center">
                    <img className="img-fluid" src={ data.images && data.images[0].localFile.publicURL }/>
                  </div>
                }
                {
                    data.images?.length >= 2 && <ImageSlider images={data.images}/>
                }
            </div>
            <div className="col-12 col-lg-6 lp-product-description d-flex align-items-center">
              <div>
                <h2>{ data.title }</h2>
                {
                  data.description &&
                  <p>{ data.description }</p>
                }
                {
                  data.features.length > 0 &&
                  <ul>
                    { data.features?.map( (featureObj, featureIndex) => {
                      return <li key={featureIndex}>{ featureObj.feature }</li>
                    })}
                  </ul>
                }
                {
                  data.documents &&
                  <p className={`lp-product-desc-docs`}>{
                    data.documents.map( (document, documentIdx) => (
                      document?.localFile?.publicURL &&
                      <a key={documentIdx} href={ document.localFile.publicURL }>
                        {
                          document.localFile.extension === `pdf` ?
                          (
                            <FiletypePdf className={`mb-2`} style={{display: `block`}} size={72}/>
                          ) : (
                            <FiletypeDoc className={`mb-2`} style={{display: `block`}} size={72}/>
                          )
                        }
                        { document.caption }
                        </a>
                    ))
                  }</p>
                }
              </div>
            </div>
          </div>
        </section>
        { data.video &&
          <section className="container lp-product-video">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 lp-product-video-col" dangerouslySetInnerHTML={{__html: process.env.NODE_ENV === 'development' ? data.video : data.video.replace(/(.*src=")([^"]*)(".*)$/, `$1$2?&autoplay=1&mute=1$3`) }}></div>
            </div>
          </section>
        }
        <section className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <table className="table lp-product-details">
                <tbody>
                    { data.productData?.map( (productInfo, productInfoIndex) => {
                      return <tr key={ productInfoIndex }>
                          <th>{ productInfo.field }</th>
                          <td>{ productInfo.value }</td>
                      </tr>
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </Layout>
    )
}

export const Head = ({ pageContext: { slug, data }}) => <Seo title={ data.title} />

export default Product
